/* eslint-disable no-console */
import { useEffect } from "react";
import { EventColor } from "../reclaim-api/EventMetaTypes";
import { isEven } from "./numbers";

const OrigDate = Date;

/* Time-Travel */

// Reset global Date
export function unmockDate() {
  if (!Date["mocked"]) return;
  Date = OrigDate;
}

export function mockDate(offset: number) {
  // Reset mock if offset changed
  if (!!Date["mocked"]) {
    if (offset === Date["offset"]) return;
    unmockDate();
  }

  // Replace global Date w/ Proxy
  Date = new Proxy(OrigDate, {
    // offset new Date()
    construct: function (Target, args: Parameters<DateConstructor>): Date {
      // props to https://stackoverflow.com/a/43160428/671457
      if (args.length === 0) return new Target(OrigDate.now() - offset);
      return new Target(...args);
    },

    get: function (Target, prop, receiver) {
      // used to detect mocked Date
      if (prop === "mocked") return true;
      if (prop === "offset") return offset;

      // offset Date.now()
      if (prop === "now") {
        return () => new Target(OrigDate.now() - offset).getTime();
      }

      return Reflect.get(Target, prop, receiver);
    },
  });
}

export function useAnnounceMount(name: string, ...data: unknown[]): void {
  function getArgs(action: string): unknown[] {
    const args: unknown[] = [`${name} ${action}`];
    if (data) args.push(...data);
    return args;
  }

  useEffect(() => {
    console.log(...getArgs("mounted"));
    return () => console.log(...getArgs("unmounted"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

export const hashNum = (val: unknown, precision = 10000): number => {
  const str = JSON.stringify(val);
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = (hash << 5) - hash + str.charCodeAt(i);
    hash |= 0;
  }

  return (Math.abs(hash) % precision) / precision;
};

export const hashNumBetween = (val: unknown, min: number, max: number): number => hashNum(val) * (max - min) + min;
export const hashIntBetween = (val: unknown, min: number, max: number): number =>
  Math.round(hashNumBetween(val, min, max));

export const hashHexColor = (val: unknown): string => {
  const hash = hashIntBetween(val, 0, 1000);
  const c = (hash & 0x00ffffff).toString(16).toUpperCase();

  return ("#" + "00000".substring(0, 6 - c.length) + c) as string;
};

export const hashInArray = <T>(val: unknown, arr: readonly T[]): T => arr[hashIntBetween(val, 0, arr.length - 1)];
export const hashEventColor = (val: unknown) => hashInArray(val, EventColor.all);

export const hashHexColorFromEventColors = (value: unknown) => {
  const eventColor = hashEventColor(value);

  switch (eventColor.key) {
    case "AUTO":
    case "NONE":
      return hashHexColor(value);
    default:
      return eventColor.hex;
  }
};

export const hashBoolean = (val: unknown): boolean => isEven(hashIntBetween(val, 0, 100));
